<template>
  <div>
    <loading v-if="loading" />
    <template v-if="! loading">
      <h3>Transaction Details</h3>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>Payment Method</td>
                    <td>{{ payment.method }}</td>
                  </tr>
                  <tr>
                    <td>Reference</td>
                    <td>{{ payment.reference }}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td><small>KES</small> {{ payment.amount }}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{{ payment.date }}</td>
                  </tr>
                  <tr>
                    <td>Account</td>
                    <td>{{ payment.account }}</td>
                  </tr>
                  <tr v-if="payment.method == 'mpesa'">
                    <td>Phone Number</td>
                    <td>{{ payment.phone_number }}</td>
                  </tr>
                  <tr>
                    <td>Paid By</td>
                    <td>{{ payment.payer }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0">Property Details</h4>
            </div>
            <div class="card-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>Property</td>
                    <td><router-link :to="{ name: 'admin.properties.show', params: { id: payment.property.id }}">{{ payment.property.name }}</router-link></td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{{ payment.property.type }}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{{ payment.property.category }}</td>
                  </tr>
                  <tr>
                    <td>Region</td>
                    <td>{{ payment.property.region }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{{ payment.property.status }}</td>
                  </tr>
                  <tr>
                    <td>Views</td>
                    <td>{{ payment.property.views }}</td>
                  </tr>
                  <tr>
                    <td>Posted By</td>
                    <td><router-link :to="{ name: 'admin.users.show', params: { id: payment.property.user.id }}">{{ payment.property.user.name }}</router-link></td>
                  </tr>
                  <tr v-if="payment.property.agency.name != null">
                    <td>Linked Agency</td>
                    <td><router-link :to="{ name: 'admin.agencies.show', params: { id: payment.property.agency.id }}">{{ payment.property.agency.name }}</router-link></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      payment: {},
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.axios.get(`/api/v1/admin/payments/${this.$route.params.id}`).then(response => {
        this.payment = response.data.payment
        this.loading = false
      })
    }
  }
}
</script>